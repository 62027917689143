import React from 'react';
import { useTranslation } from 'hooks/useTranslation';
import { Table, Label } from '@gravity-ui/uikit';
import { MainPageCard } from 'components/MainPageView';


const DeviceAutoTestingTable = ({ tests }) => {
    const { t } = useTranslation("device_registration");
    return (
        <MainPageCard>
            <Table
                data={tests}
                columns={[
                    { 
                        id: 'test_name', 
                        name: t("device_test_name"),
                        width: 1000 
                    },
                    { 
                        id: 'component_name', 
                        name: t("device_test_component"),
                        width: 1000 
                    },
                    { 
                        id: 'status', 
                        name: t("device_test_status"),
                        width: 1000, 
                        template: (row) => {
                        return <Label theme={row.status === 'passed' ? 'success' : 'danger'}>
                                    {row.status==='passed'?t("device_test_pass"):t("device_test_fail")}
                               </Label>
                    } },
                    { 
                        id: 'range', 
                        name: t("device_test_range"), 
                        width: 1000 
                    },
                    { 
                        id: 'value', 
                        name: t("device_test_value"),
                        width: 1000 
                    },
                ]}
            />
        </MainPageCard>
    )
}

export default DeviceAutoTestingTable;