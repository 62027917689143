import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'hooks/useTranslation';
import { Card, Button, RadioGroup, RadioButton, Slider } from '@gravity-ui/uikit';
import { Stepper, Step, StepLabel } from '@mui/material';
import './index.css'
import qCoreApi from 'api';


const ManualDeviceTest = ({test, device, selected_results, setSelectedResults, test_session_id, device_id}) => {
    const { t } = useTranslation("device_registration");
    const [images, setImages] = useState({ left_image: '', right_image: '' });
    const [loading, setLoading] = useState(true);
    const [start_pwm, setStartPWM] = useState(false);
    const [pwm, setPwm] = useState(10);

    const load_image = (retryCount = 0, side) => {
        qCoreApi.get_camera_images(
            test_session_id,
            device_id,
            side,
            (data) => {
                console.log('Fetched images:', data);
                
                setImages((prevImages) => {
                    return {
                        ...prevImages,
                        [`${side}_image`]: data,
                    };
                });
                setLoading(false);
            },
            (error) => {
                console.error('Error fetching images:', error);
                if (retryCount < 3) {
                    console.log(`Retrying... (${retryCount + 1})`);
                    load_image(retryCount + 1, side);
                } else {
                    setLoading(false);
                }
            }
        );
    };

    useEffect(() => {
        if (test.id === "Display Test") {
        qCoreApi.show_image(device.uuid, (data) => {}, (error) => {
            console.error('Error showing image:', error);
        });
        return () => {
            qCoreApi.close_image(device.uuid, (data) => {}, (error) => {
                console.error('Error closing image:', error);
            })
        };
        }
    }, [test.id === "Display Test"]);

    useEffect(() => {
        setLoading(true);
        console.log("device_id", device_id);
        console.log("test_session_id", test_session_id);
        console.log("device", device);
       
        

        qCoreApi.request_camera_images(test_session_id, device_id, (data) => {load_image(0, "left"); load_image(0, "right")}, 
            (error) => {
                console.error('Error fetching images:', error);
                setLoading(false);
            });
        

    }, [test_session_id, device_id]);
   
    const left_camera_options = [
        {
            value: 'passed',
            content: t("device_camera_test_success")
        },
        {
            value: 'fail',
            content: t("device_camera_test_fail")
        }]

    const cooling_test_options = [
        {
            value: 'passed',
            content: t("device_cooling_test_success")
        },
        {
            value: 'fail',
            content: t("device_cooling_test_fail")
        }]
        
    const display_test_options = [
        {
            value: 'passed',
            content: t("device_display_test_success")
        },
        {
            value: 'fail',
            content: t("device_display_test_fail")
        }]

    

    if (test.id === "Left Camera Test") {
        console.log("left camera 111")
        return (
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '20px',
                    width: '100%',
                    justifyContent: 'baseline',
                    alignItems: 'baseline',
                }}
            >
                <h3>{test.name}</h3>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '20px',
                        width: '100%',
                        
                        
                    }}
                >
                    {loading ? (
                        <p>{t("loading_images")}</p>
                    ) : (
                        <img
                            src={`${images.left_image}`}
                            alt="Left Camera"
                            style={{
                                maxWidth: '100%',
                                maxHeight: '500px',
                                height: 'auto',
                                width: 'auto',
                                display: 'block',
                                margin: '0 auto'
                            }}
                        />
                    )}
                    <RadioGroup 
                        name="group1"
                        defaultValue='' 
                        options={left_camera_options} 
                        direction="vertical"
                        value = {selected_results[0]}
                        onUpdate={(value) => { setSelectedResults({ ... selected_results, 0: value}) }}
                    />
                </div>
            </div>
        )
    } else if (test.id === "Right Camera Test") {
        console.log("right camera 111")
        return (
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '20px',
                    width: '100%',
                    justifyContent: 'baseline',
                    alignItems: 'baseline',
                }}
            >
                <h3>{test.name}</h3>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '20px',
                        width: '100%',
                    }}
                >
                    {loading ? (
                        <p>{t("loading_images")}</p>
                    ) : (
                        <img
                            src={`${images.right_image}`}
                            alt="Left Camera"
                            style={{
                                maxWidth: '100%',
                                maxHeight: '500px',
                                height: 'auto',
                                width: 'auto',
                                display: 'block',
                                margin: '0 auto'
                            }}
                        />)}
                    <RadioGroup 
                        name="group2" 
                        defaultValue='' 
                        options={left_camera_options} 
                        direction="vertical"
                        value = {selected_results[1]}
                        onUpdate={(value) => { setSelectedResults({ ... selected_results, 1: value}) }}
                    />
                </div>
            </div>
        )
    } else if (test.id === "Cooling Test") {
        return (
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '20px',
                    width: '100%',
                    justifyContent: 'baseline',
                    alignItems: 'baseline',
                }}
            >
                <h3>{test.name}</h3>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '20px',
                        width: '100%',
                        alignItems: 'baseline',
                    }}
                >
                    <Card
                        size='l'
                        style={{
                            padding: '20px',
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'start',
                            alignItems: 'baseline',
                            gap: '20px',
                        }}
                    >
                        <Button
                            size='xl'
                            onClick = {async () => {

                                if (start_pwm) {
                                    qCoreApi.set_pwm(test_session_id, device_id, 0,
                                        (data) => {
                                            console.log(data);
                                        },
                                        (error) => {
                                            console.error('Error starting pwm:', error);
                                        }
                                    )
                                } else {
                                    qCoreApi.set_pwm(test_session_id, device_id, pwm,
                                        (data) => {
                                            console.log(data);
                                        },
                                        (error) => {
                                            console.error('Error starting pwm:', error);
                                        }
                                    )
                                }

                                setStartPWM(!start_pwm);

                            }
                            }
                        >
                            {!start_pwm? t("device_cooling_test_start_button") : t("device_cooling_test_finish_button")}
                        </Button>
                        <RadioButton
                            onUpdate={async (value) => { 
                                let pwm = 0;
                                if (value === 'min') {
                                    pwm = 10;
                                } else if (value === 'avg') {
                                    pwm = 100;
                                } else if (value === 'max') {
                                    pwm = 255;
                                }
                                
                                if(start_pwm) {
                                qCoreApi.set_pwm(test_session_id, device_id, pwm, 
                                    (data) => {
                                        console.log(data);
                                    },
                                    (error) => {
                                        console.error('Error setting pwm:', error);
                                    }
                                )}
                                setPwm(pwm);
                            }}
                            size='xl'
                            options={
                                [
                                    {
                                        value: "min",
                                        content: t("device_cooling_test_min")
                                    },
                                    {
                                        value: "avg",
                                        content: t("device_cooling_test_avg")
                                    },
                                    {
                                        value: "max",
                                        content: t("device_cooling_test_max")
                                    }
                                ]
                            }
                            />
                    </Card>
                    <RadioGroup 
                        name="group2" 
                        defaultValue='' 
                        options={cooling_test_options} 
                        direction="vertical"
                        value = {selected_results[2]}
                        onUpdate={(value) => { setSelectedResults({ ... selected_results, 2: value}) }}
                    />
                </div>
            </div>
        )
    } else if (test.id === "Display Test") {
        return (
            <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '20px',
                width: '100%',
                justifyContent: 'baseline',
                alignItems: 'baseline',
            }}
            >
            <h3>{test.name}</h3>
            <div
                style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '20px',
                width: '100%',
                
                
                }}
            >
                <div
                style={{
                    maxWidth: '500px',
                    
                }}
                >
                <div>
                <Slider 
                size='xl'
                
                onUpdate={(() => {
                    let debounceTimer;
                    return (value) => {
                    clearTimeout(debounceTimer);
                    debounceTimer = setTimeout(() => {
                        qCoreApi.set_brightness(test_session_id, device_id, (2.55 * value).toFixed(0),
                        (data) => {
                            console.log(data);
                        },
                        (error) => {
                            console.error('Error setting brightness:', error);
                        }
                        );
                    }, 1000); // 1 second delay
                    };
                })()}
                />
                </div>
                <div
                    style = {{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: '20px',
                    alignItems: 'center',
                    justifyContent: 'center',
                    }}
                >
                    <div className='color-choose' style={{backgroundColor: 'red', width : '40px', height:'40px', borderRadius: '50%'}}
                        onClick = {() => {
                            qCoreApi.show_color(device.uuid, 'red', (data) => {}, (error) => {
                            console.error('Error showing color:', error);
                            }
                            )
                        }}
                    />
                    <div className='color-choose' style={{backgroundColor: 'green', width : '40px', height:'40px', borderRadius: '50%'}}
                        onClick = {() => {
                            qCoreApi.show_color(device.uuid, 'green', (data) => {}, (error) => {
                            console.error('Error showing color:', error);
                            }
                            )
                        }}
                    />
                    <div className='color-choose' style={{backgroundColor: 'blue', width : '40px', height:'40px', borderRadius: '50%'}}
                        onClick = {() => {
                            qCoreApi.show_color(device.uuid, 'blue', (data) => {}, (error) => {
                            console.error('Error showing color:', error);
                            }
                            )
                        }}
                    />
                    <div className='color-choose' style={{backgroundColor: 'white', width : '40px', height:'40px', borderRadius: '50%', border:'1px solid black'}}
                        onClick = {() => {
                            qCoreApi.show_color(device.uuid, 'white', (data) => {}, (error) => {
                            console.error('Error showing color:', error);
                            }
                            )
                        }}
                    />
                    
                    
                </div>
                </div>
                <RadioGroup 
                name="group2" 
                defaultValue='' 
                options={display_test_options} 
                direction="vertical"
                value = {selected_results[3]}
                onUpdate={(value) => { setSelectedResults({ ... selected_results, 3: value}) }}
                />
            </div>
            </div>
        )
    }
}


const ManualDeviceTesting = ({onNextStep, session_id, step, device, selected_results, setSelectedResults, manualTests}) => {
    const { t } = useTranslation("device_registration");
    const tests = manualTests;



    return <div
            style ={{
                display: 'flex',
                flexDirection: 'row',
                gap: '40px',
                width: '100%',
                justifyContent: 'center',
                alignItems: 'baseline',
                marginTop: '0px'
            }}

        >
        <Card

            size='l'
            style={{
                flex:1,
                
                minWidth: '300px',
                paddingTop: '25px',
                paddingBottom: '25px',
                paddingLeft: '25px',
                paddingRight: '25px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'baseline',
                gap: '10px'
            }}
        >
            <Stepper
                orientation="vertical"
                activeStep={step}
                
            >
                {tests.map((test, index) => (
                    <Step key={test.name}>
                        
                        <StepLabel icon={''}>{test.name}</StepLabel>
                    </Step>
                ))}
            </Stepper>
        </Card>  
        <Card
            style = {{
                flex: 5,
                paddingTop: '25px',
                paddingBottom: '25px',
                paddingLeft: '25px',
                paddingRight: '25px',
            }}
        >
            <ManualDeviceTest
                test={manualTests[step]}
                device={device}
                selected_results={selected_results}
                setSelectedResults={setSelectedResults}
                test_session_id={session_id}
                device_id={device.uuid}
            />
        </Card>

    </div>
}


export default ManualDeviceTesting;