import {api_endpoint} from 'constants/api';
import { oidc_config } from 'constants/auth'; 
import { device } from 'pages/devices/registration/test_data';

const client_id = oidc_config.client_id;

const getAuthHeader = (content_type = "application/json") => {
    const oidcStorage = localStorage.getItem(`oidc.user:${oidc_config.authority}:${client_id}`);
    const  access_token= JSON.parse(oidcStorage).access_token;
    const myHeaders = new Headers();
    myHeaders.append('qcore-jwt-token', access_token);
    if (content_type) {
        myHeaders.append('Content-Type', content_type);
    }
    return myHeaders;
}

const TEST_ENDPOINT=api_endpoint + 'device-registration/api/v1';


class QCoreApi {
    constructor() {
    }

    async get_services(onSuccess, onError) {
        const requestOptions = {
            method: 'GET',
            headers: getAuthHeader()
        };
        const res = await fetch(`${api_endpoint}/webpanel-server/services`, requestOptions)
        if (res.ok) {
            const data = await res.json();
            onSuccess(data);
        } else {
            const data = await res.json();
            console.log(data);
            onError(JSON.stringify(data));
        }
    }

    async get_policies(onSuccess, onError) {
        const requestOptions = {
            method: 'GET',
            headers: getAuthHeader()
        };
        const res = await fetch(`${api_endpoint}/webpanel-server/policies`, requestOptions)
        if (res.ok) {
            const data = await res.json();
            onSuccess(data);
        } else {
            const data = await res.json();
            console.log(data);
            onError(JSON.stringify(data));
        }
    }

    async get_user_info(onSuccess, onError) {
        const requestOptions = {
            method: 'GET',
            headers: getAuthHeader()
        };
        const res = await fetch(`${api_endpoint}/webpanel-server/user`, requestOptions)
        if (res.ok) {
            const data = await res.json();
            onSuccess(data);
        } else {
            const data = await res.json();
            console.log(data);
            onError(JSON.stringify(data));
        }
    }

    async get_policies_targets(onSuccess, onError) {
        const requestOptions = {
            method: 'GET',
            headers: getAuthHeader()
        };
        const res = await fetch(`${api_endpoint}/webpanel-server/policies/device/targets`, requestOptions)
        if (res.ok) {
            const data = await res.json();
            onSuccess(data);
        } else {
            const data = await res.json();
            console.log(data);
            onError(JSON.stringify(data));
        }
    }

    async get_service_deployment_status(onSuccess, onError) {
        const requestOptions = {
            method: 'GET',
            headers: getAuthHeader()
        };
        const res = await fetch(`${api_endpoint}/webpanel-server/services/deployment/status`, requestOptions)
        if (res.ok) {
            const data = await res.json();
            onSuccess(data);
            console.log(data);
            onError(JSON.stringify(data));
        }
    }

    async get_devices(onSuccess, onError) {
        const requestOptions = {
            method: 'GET',
            headers: getAuthHeader()
        };
        const res = await fetch(`${api_endpoint}/webpanel-server/devices`, requestOptions)
        if (res.ok) {
            const data = await res.json();
            onSuccess(data);
        } else {
            const data = await res.json();
            console.log(data);
            onError(JSON.stringify(data));
        }
    }

    async get_device_status(device_id, onSuccess, onError) {
        const requestOptions = {
            method: 'GET',
            headers: getAuthHeader()
        };
        const res = await fetch(`${api_endpoint}/webpanel-server/devices/${device_id}/status`, requestOptions)
        if (res.ok) {
            const data = await res.json();
            onSuccess(data);
        } else {
            const data = await res.json();
            console.log(data);
            onError(JSON.stringify(data));
        }
    }

   
        

    async get_device_logs(device_id, onSuccess, onError) {
        const requestOptions = {
            method: 'GET',
            headers: getAuthHeader()
        };
        const res = await fetch(`${api_endpoint}/webpanel-server/devices/${device_id}/logs`, requestOptions)
        if (res.ok) {
            const data = await res.json();
            onSuccess(data.logs);
        } else {
            const data = await res.json();
            console.log(data);
            onError(JSON.stringify(data));
        }
    }

    async apply_policy(service_id, policy_id, onSuccess, onError) {
        const requestOptions = {
            method: 'POST',
            headers: getAuthHeader(),
           
        };
        const res = await fetch(`${api_endpoint}/webpanel-server/services/${service_id}/apply/policy/${policy_id}`, requestOptions)
        if (res.ok) {
            const data = await res.json();
            onSuccess(data);
        } else {
            const data = await res.json();
            console.log(data);
            onError(JSON.stringify(data));
        }
    }

    async add_device(name, config, onSuccess, onError) {
        const requestOptions = {
            method: 'POST',
            headers: getAuthHeader(),
            body: JSON.stringify({
                name: name,
                config: config
            })
        };
        const res = await fetch(`${api_endpoint}/webpanel-server/devices`, requestOptions)
        if (res.ok) {
            const data = await res.json();
            onSuccess(data);
        } else {
            const data = await res.json();
            console.log(data);
            onError(JSON.stringify(data));
        }
    }

    async update_device(device_id, name, config, onSuccess, onError) {
        const requestOptions = {
            method: 'PUT',
            headers: getAuthHeader(),
            body: JSON.stringify({
                name: name,
                config: config
            })
        };
        const res = await fetch(`${api_endpoint}/webpanel-server/devices/${device_id}`, requestOptions)
        if (res.ok) {
            const data = await res.json();
            onSuccess(data);
        } else {
            const data = await res.json();
            console.log(data);
            onError(JSON.stringify(data));
        }
    }

// Device Registration API

    async get_devices_registration_info(onSuccess, onError) {
        const requestOptions = {
            method: 'GET',
            headers: getAuthHeader()
        };
        const res = await fetch(`${TEST_ENDPOINT}/devices/`, requestOptions)
        if (res.ok) {
            const data = await res.json();
            onSuccess(data);
        } else {
            const data = await res.json();
            console.log(data);
            onError(JSON.stringify(data));
        }
    }

    async get_device_registration_status(pin_number, onSuccess, onError) {
        const requestOptions = {
            method: 'GET',
            headers: getAuthHeader()
        };
        const res = await fetch(`${TEST_ENDPOINT}/code/${pin_number}/status`, requestOptions);
        console.log(res)
        if (res.ok) {
            // console.log(res)
            const data = await res.json();
            onSuccess(data);
        } else {
            const data = await res.json();
            // console.log(data);
            onError(JSON.stringify(data));
        }
    }

    async verify(pin_number, onSuccess, onError) {
        const requestOptions = {
            method: 'POST',
            headers: getAuthHeader()
        };
        const res = await fetch(`${TEST_ENDPOINT}/code/${pin_number}/verify`, requestOptions);
        console.log(res)
        if (res.ok) {
            // console.log(res)
            const data = await res.json();
            onSuccess(data);
        } else {
            const data = await res.json();
            // console.log(data);
            onError(JSON.stringify(data));
        }
    }
    
    async get_autotests_results(device_id, session_id, onSuccess, onError) {
        const requestOptions = {
            method: 'GET',
        };
        const res = await fetch(`${TEST_ENDPOINT}/tests/autotests/result/${device_id}/${session_id}`, requestOptions);
        console.log(res)
        if (res.ok) {
            console.log(res)
            const data = await res.json();
            onSuccess(data);
        } else {
            const data = await res.json();
            console.log(data);
            onError(JSON.stringify(data));
        }
    }

    async run_autotests(device_id, onSuccess, onError) {
        const requestOptions = {
            method: 'POST',
        };
        const res = await fetch(`${TEST_ENDPOINT}/tests/autotests/run/${device_id}`, requestOptions);
        console.log(res)
        if (res.ok) {
            console.log(res)
            const data = await res.json();
            onSuccess(data);
        } else {
            const data = await res.json();
            console.log(data);
            onError(JSON.stringify(data));
        }
    }

    async ping_device(device_id, onSuccess, onError) {
        const requestOptions = {
            method: 'POST',
        };
        const res = await fetch(`${TEST_ENDPOINT}/devices/${device_id}/ping`, requestOptions);
        console.log(res)
        if (res.ok) {
            console.log(res)
            const data = await res.json();
            onSuccess(data);
        } else {
            const data = await res.json();
            console.log(data);
            onError(JSON.stringify(data));
        }
    }


    async send_manual_test_results(testResults, onSuccess, onError) {
        console.log(testResults);
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(testResults),
        };
        
        try {
            const response = await fetch(`${TEST_ENDPOINT}/tests/result`, requestOptions);
            if (response.ok) {
                const responseData = await response.json();
                onSuccess(responseData);
            } else {
                let errorData;
                try {
                    errorData = await response.json();
                } catch (e) {
                    errorData = { message: 'Unknown error occurred' };
                }
                onError(JSON.stringify(errorData));
            }
        } catch (error) {
            console.error('Error:', error);
            onError(error.message);
        }
    }

    async request_camera_images(test_session_id, device_id, onSuccess, onError) {
        const requestOptions = {
            method: 'POST',
            headers: getAuthHeader(),
            body: JSON.stringify({
                test_session_id: test_session_id,
                device_id: device_id    
            })
        };
    
        try {
            const response = await fetch(`${TEST_ENDPOINT}/tests/manual/camera-image`, requestOptions);
            if (response.ok) {
                const data = await response.json();
                onSuccess(data);
            } else {
                const errorData = await response.json();
                onError(errorData);
            }
        } catch (error) {
            console.error('Error fetching camera images:', error);
            onError(error);
        }
    }
   

    async get_camera_images(test_session_id, device_id, side, onSuccess, onError) {
        const requestOptions = {
            method: 'GET',
            headers: getAuthHeader()
        };
    
        try {
            console.log(TEST_ENDPOINT)
            const response = await fetch(`${TEST_ENDPOINT}/tests/manual/camera-image/${test_session_id}/${side}?device_id=${device_id}`, requestOptions);
            if (response.ok) {
                const blob = await response.arrayBuffer();
                const image = URL.createObjectURL(new Blob([blob]));
                onSuccess(image);
            } else {
                const errorData = await response.json();
                onError(JSON.stringify(errorData));
            }
        } catch (error) {
            console.error('Error fetching camera images:', error);
            onError(error);
        }
    }

    async set_pwm(test_session_id, device_id, pwm, onSuccess, onError) {
        const requestOptions = {
            method: 'POST',
            headers: getAuthHeader(),
            body: JSON.stringify({
                test_session_id: test_session_id,
                device_id: device_id,
                value: pwm
            })
        };
    
        try {
            const response = await fetch(`${TEST_ENDPOINT}/tests/manual/set-pwm`, requestOptions);
            if (response.ok) {
                const data = await response.json();
                onSuccess(data);
            } else {
                const errorData = await response.json();
                onError(errorData);
            }
        } catch (error) {
            console.error('Error setting pwm:', error);
            onError(error);
        }
    }

    async set_brightness(test_session_id, device_id, brightness, onSuccess, onError) {
        const requestOptions = {
            method: 'POST',
            headers: getAuthHeader(),
            body: JSON.stringify({
                test_session_id: test_session_id,
                device_id: device_id,
                value: brightness
            })
        };
    
        try {
            const response = await fetch(`${TEST_ENDPOINT}/tests/manual/change-brightness`, requestOptions);
            if (response.ok) {
                const data = await response.json();
                onSuccess(data);
            } else {
                const errorData = await response.json();
                onError(errorData);
            }
        } catch (error) {
            console.error('Error setting brightness:', error);
            onError(error);
        }
    }

    async show_image(device_id, onSuccess, onError) {
        const requestOptions = {
            method: 'POST',
            headers: getAuthHeader()
        };
    
        try {
            const response = await fetch(`${TEST_ENDPOINT}/devices/${device_id}/show-image`, requestOptions);
            if (response.ok) {
                const data = await response.json();
                onSuccess(data);
            } else {
                const errorData = await response.json();
                onError(errorData);
            }
        } catch (error) {
            console.error('Error showing image:', error);
            onError(error);
        }
    }

    async show_color(device_id, color, onSuccess, onError) {
        const requestOptions = {
            method: 'POST',
            headers: getAuthHeader()
        };
    
        try {
            const response = await fetch(`${TEST_ENDPOINT}/devices/${device_id}/show-image/${color}`, requestOptions);
            if (response.ok) {
                const data = await response.json();
                onSuccess(data);
            } else {
                const errorData = await response.json();
                onError(errorData);
            }
        } catch (error) {
            console.error('Error showing image:', error);
            onError(error);
        }
    }

    async close_image(device_id, onSuccess, onError) {
        const requestOptions = {
            method: 'POST',
            headers: getAuthHeader()
        };
    
        try {
            const response = await fetch(`${TEST_ENDPOINT}/devices/${device_id}/close-image`, requestOptions);
            if (response.ok) {
                const data = await response.json();
                onSuccess(data);
            } else {
                const errorData = await response.json();
                onError(errorData);
            }
        } catch (error) {
            console.error('Error closing image:', error);
            onError(error);
        }
    }


    async set_device_status(device_id, status, onSuccess, onError) {
        const requestOptions = {
            method: 'POST',
            body: JSON.stringify({status: status}),
            headers: getAuthHeader()
        };
    
        try {
            const response = await fetch(`${TEST_ENDPOINT}/devices/${device_id}/status?status=${status}`, requestOptions);
            if (response.ok) {
                const data = await response.json();
                onSuccess(data);
            } else {
                const errorData = await response.json();
                onError(errorData);
            }
        } catch (error) {
            console.error('Error Setting Status:', error);
            onError(error);
        }
    }

    async send_report(device_id, session_id, report_pdf, onSuccess, onError) {
        const formData = new FormData();

        // If `report_pdf` is a base64 data URL, you'll need to convert that into raw bytes
        // Otherwise, if `report_pdf` is already the raw PDF bytes, skip the conversion step.
        const base64Data = report_pdf.split(",")[1]; // remove `data:application/pdf;base64,`
        const byteCharacters = atob(base64Data);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const pdfBlob = new Blob([byteArray], { type: "application/pdf" });
        
        // Append the file to the formData
        formData.append("file", pdfBlob, "file");

        const requestOptions = {
            method: "POST",
            headers: getAuthHeader(null),
            body: formData
        };
        console.log(device_id, session_id)
        try {
            const response = await fetch(`${TEST_ENDPOINT}/devices/${device_id}/report?test_session_id=${session_id}`, requestOptions);
            if (response.ok) {
                const data = await response.json();
                onSuccess(data);
            } else {
                const errorData = await response.json();
                onError(errorData);
            }
        } catch (error) {
            console.error('Error sending report:', error);
            onError(error);
        }
    }

    async get_report(device_id, session_id, onSuccess, onError) {
        const requestOptions = {
            method: 'GET',
            headers: getAuthHeader()
        };
    
        try {
            const response = await fetch(`${TEST_ENDPOINT}/devices/${device_id}/report?test_session_id=${session_id}`, requestOptions);
            if (response.ok) {
                const data = await response.blob();
                onSuccess(data);
            } else {
                const errorData = await response.json();
                onError(errorData);
            }
        } catch (error) {
            console.error('Error fetching report:', error);
            onError(error);
        }
    }

}  


const qCoreApi = new QCoreApi();

export default qCoreApi;