import React, { useEffect, useRef, useState, forwardRef} from 'react';
import { useTranslation } from 'hooks/useTranslation';
import { useNavigate, useParams } from 'react-router-dom';

import { Modal } from '@gravity-ui/uikit';

import MainPageView from 'components/MainPageView';
import {DownPageSteps} from './utils';
import DeviceTable from './DeviceTable';
import DeviceMainInfo from './MainInfo';
import DeviceQRPrinting from './QRPrinter';

import SelectDeviceType from './SelectType';
import DeviceServiceTable from './ServiceTable';
import DeviceAutoTestingTable from './AutoTesting';
import ManualDeviceTesting from './ManualTesting';
import DeviceReport from './Report';
import qCoreApi from 'api';
import {useToaster} from '@gravity-ui/uikit';
import { use } from 'react';
import { api_endpoint } from 'constants/api';


const DeviceRegistrationPage = () => {
    console.log(process.env.REACT_APP_BASE_URL)
    const { t } = useTranslation("device_registration");

    const {add} = useToaster();

    const [step, setStep] = React.useState(0);
    const [manual_step, setManualStep] = React.useState(0);
    const [pin_number, setPinNumber] = React.useState('');
    const [test_session_id, setTestSessionId] = React.useState('');

    const [device, setDevice] = React.useState({});

    const [selected_results, setSelectedResults] = useState({
        0:'fail',
        1:'fail',
        2:'fail',
        3:'fail'}
    )

    const [devices, setDevices] = React.useState([]);
    
    const [tests, setTests] = React.useState([]);
    const [services, setServices] = React.useState([]);
    const [manualTests, setManualTests] = React.useState([
        {
            id: "Left Camera Test",
            name: t("device_test_left_camera_name"),
            test_id: "a7e94d12-ab65-4686-ba44-2971103b5d72",
            component_id: "49f3f25d-1b36-450e-8510-95ec1893c0e8",
            component_name : "Camera"
        },
        {
            id: "Right Camera Test",
            name: t("device_test_right_camera_name"),
            test_id: "a7e94d12-ab65-4686-ba44-2971103b5d72",
            component_id: "9c9ebb5e-22b9-4c21-9577-8631f66e6cfe",
            component_name : "Camera"
        },
        {
            id: "Cooling Test",
            name: t("device_cooling_test_name"),
            test_id: "b399b871-4fb6-42f7-b563-908d7c107e9f",
            component_id: "c17dec31-7137-4f67-a66a-2602d49dece6",
            component_name : "Cooling"
        },
        {
            id: "Display Test",
            name: t("device_display_test_name"),
            test_id: "b3546cff-6c6f-4cc7-a4d7-2ec098806256",
            component_id: "ede4c26f-a8ef-4853-9e93-ecb2d4f98f45",
            component_name : "Display"
        },
    ]);

    const sendManualTestResults = async () => {
        const testResults = Object.keys(selected_results).map((index) => {
            const test = manualTests[index];
            return {
                status: selected_results[index],
                value: 0,
                metadata: {},
                running_at: new Date().toISOString(),
                finished_at: new Date().toISOString(),
                test_id: test.test_id,
                device_id: device.uuid,
                component_id: test.component_id,
            };
        });
    
        await qCoreApi.send_manual_test_results(
            testResults,
            (data) => {
                console.log('Manual test results sent successfully', data);
            },
            (error) => {
                console.error('Error sending manual test results', error);
                add({
                    title: "Can't send manual test results",
                    isClosable: true,
                    theme: 'danger',
                    content: 'Please try again later',
                    autoHiding: 5000,
                    action: ['Retry'],
                });
            }
        );
    };

    const [device_types, setDeviceTypes] = React.useState([
        {
            value: 'Almaty 2024',
            content: 'Almaty 2024'
        }
    ]
    );

    const [selectedDeviceType, setSelected] = React.useState([]);
    const [showReport, setShowReport] = React.useState(false);

    const [install, setInstall] = React.useState(false);


    const navigate = useNavigate();
    const params = useParams();

    const onClose = () => {
        setStep(0);
        setManualStep(0);
        setShowReport(false);
        setSelected([]);
        setInstall(false);
        setDevice({});
        setPinNumber('');
        setTestSessionId('');
    }


    useEffect(() => {
        qCoreApi.get_devices_registration_info((data) => {
            console.log(data);
            setDevices(data.map((x) => {
                return {
                    uuid: x["id"],
                    ...x
                }
            }))
        }, (error) => {
            console.log(error);
            add({
                'title' : "Can't get devices",
                'isClosable' : true,
                
            })

        })
    }, []);

    useEffect(() => {
        const pingDevice = () => {
            if (device.uuid) {
                qCoreApi.ping_device(device.uuid, (data) => {
                    console.log('Device pinged successfully', data);
                    if (data.status === 'active') {
                        setDevice((prev) => ({
                            ...prev, online: true
                        }));
                    } else {
                        setDevice((prev) => ({
                            ...prev, online: false
                        }));
                    }
                   
                }, (error) => {
                    console.error('Error pinging device', error);
                    setDevice((prev) => ({
                        ...prev, online: false
                    }));
                });
            }
        };

        pingDevice();

        const interval = setInterval(pingDevice, 30000);

        return () => clearInterval(interval);
    }, [device.uuid]);

    useEffect(() => {
        let interval = null;
        const run_autotests = async (n) => {
            if (n > 4) {
                add({
                    'title' : "Can't run autotests",
                    'isClosable' : true,
                    'theme' : 'danger',
                    'content' : 'Please try again later',
                    'autoHiding': 5000,
                    'action' : ['Retry']
                });
                return () => clearTimeout(interval);
            }
            if (device.uuid) {
                await qCoreApi.run_autotests(device.uuid, (data) => {
                    console.log(data);
                    setTestSessionId(data.session_id);
                }, (error) => {
                    interval = setTimeout(() => run_autotests(n + 1), 5000);
                });
            }
        };

        if (step === 2) {
            run_autotests(0);
        }

        return () => clearTimeout(interval);
    }, [device.uuid, step]);

    useEffect(() => {
        const get_autotests = async () => {
            if (step === 2) {
                if (test_session_id) {
                    await qCoreApi.get_autotests_results(device.uuid, test_session_id, (data) => {
                        console.log(data);
                        setTests(data);
                        if (data.length > 0) {
                            clearInterval(interval);
                        }
                    }, (error) => {
                        console.log(error);
                    });
                }
            }
        };
        get_autotests();
        const interval = setInterval(get_autotests, 5000);
        return () => clearInterval(interval);

    }, [test_session_id, device.uuid]);

    if (step === 0) {
        return <DeviceTable
            devices={devices}
            onDeviceClick={(device) => {
                setPinNumber(device.pin_code);
                setDevice({
                    name: device.name,
                    uuid: device.device_id,
                    status: device.status,
                    created_at : device.created_at
                    
                });
                setStep(1);
            }}
            onNextStep={async (pin_number) => {
                console.log(pin_number)
                await qCoreApi.get_device_registration_status(pin_number, async (data) => {
                    setDevice(data.device);
                    setStep(1);
                    setPinNumber(pin_number);
                }
                , (error) => {
                    console.log(error);
                    add({
                        'title' : "Can't register device",
                        'isClosable' : true,
                        'theme' : 'danger',
                        'content' : 'Please try again later',
                        'autoHiding': 5000,
                        'action' : ['Retry']
                    })
                })
                
            }}
        />
    } else if (step === 1) {
        return <MainPageView>
            <DeviceMainInfo
                name={device.name}
                uuid={device.uuid}
                status={device.online ? "online" : "offline"}
                step={step-1}
                onClose={onClose}
            />
            <DeviceQRPrinting
                name={device.name}
                uuid={device.uuid}
            />
            <DownPageSteps
                hasPrevious={false}
                rightContent={t("next_step")}
                onNext={async () => {
                    await qCoreApi.verify(pin_number, (data) => {
                        console.log(data);
                        if (data.status !== 'verified') {
                            add({
                                'title' : "Can't register device",
                                'isClosable' : true,
                                'theme' : 'danger',
                                'content' : 'Please try again later',
                                'autoHiding': 5000,
                                'action' : ['Retry']
                            })
                        } else {
                            setStep(2);
                            
                        }
                    },
                    (error) => {
                        console.log(error);
                        add({
                            'title' : "Can't register device",
                            'isClosable' : true,
                            'theme' : 'danger',
                            'content' : 'Please try again later',
                            'autoHiding': 5000,
                            'action' : ['Retry']
                        })
                    });
                    
                }}
            />
        </MainPageView>
    } else if (step === 4) {
        return <MainPageView>
            <DeviceMainInfo
                name={device.name}
                uuid={device.uuid}
                status={device.online ? "online" : "offline"}
                step={step-1}
                onClose={onClose}
            />
            <SelectDeviceType 
                onSelect={setSelected}
                onInstall={() => setInstall(true)}
                selected={selectedDeviceType}
                options={device_types}
            />
            {
                install ? < DeviceServiceTable services={services}/> : null
            }
             
            <DownPageSteps
                hasPrevious={true}
                onPrevious={() => {setStep(3); setManualStep(0)}}
                rightContent={t("finish_step")}
                leftContent={t("previous_step")}
                onNext={() => {
                    
                    console.log(device)
                    setShowReport(true);
                    
                }}
            />
            <Modal
                open={showReport}
                onClose={() => setShowReport(false)}
            >
                <DeviceReport
                    device_name={device.name}
                    uuid={device.uuid}
                    status = {
                        Object.values(selected_results).every(result => result === 'passed') && Array.prototype.every.call(tests, (x) => x.status === 'passed') ? 'passed' : 'fail'
                    }
                    registraion_date={new Date(device.created_at + "Z")}
                    installation_date={new Date()}
                    autotests={tests}
                    test_session_id={test_session_id}
                    manual_tests={manualTests.map((x) => {
                        return {
                            test_name: x.name,
                            component_name: x.component_name,
                            status: selected_results[manualTests.indexOf(x)] === 'passed' ? 'passed' : 'fail'
                        }
                    })}
                    onClose={onClose}
                />
            </Modal>
        </MainPageView>
    } else if (step  === 2) {
        return <MainPageView>
            <DeviceMainInfo
                name={device.name}
                uuid={device.uuid}
                status={device.online ? "online" : "offline"}
                step={step-1}
                onClose={onClose}
            />
            <DeviceAutoTestingTable
                tests = {tests}
            />
            <DownPageSteps
                hasPrevious={true}
                onPrevious={() => setStep(1)}
                rightContent={t("next_step")}
                leftContent={t("previous_step")}
                onNext={() => {setStep(3); setManualStep(0)}}
            />
        </MainPageView>
    } else if (step === 3) {
        return <MainPageView>
            <DeviceMainInfo
                name={device.name}
                uuid={device.uuid}
                status={device.online ? "online" : "offline"}
                step={step-1}
                onClose={onClose}
            />
            <ManualDeviceTesting 
                selected_results={selected_results}
                setSelectedResults={setSelectedResults}
                step={manual_step}
                device={device}
                session_id={test_session_id}
                manualTests={manualTests}
                
            />
            <DownPageSteps
                hasPrevious={true}
                onPrevious={() => {
                    if (manual_step > 0) {
                        setManualStep(manual_step-1)
                    } else {
                        setStep(2)
                        setManualStep(0)
                    }
                }}
                rightContent={t("next_step")}
                leftContent={t("previous_step")}
                onNext={() => {
                    if (manual_step < 3) {
                        setManualStep(manual_step + 1);
                    } else {
                        setStep(4);
                        setManualStep(0);
                        
                    }
                }}
            />
          
        </MainPageView>
    } 
};


export default DeviceRegistrationPage;