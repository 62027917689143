import React, { forwardRef, useRef } from 'react';
import logo from 'assets/logo192.png';
import QRCode from 'react-qr-code';
import { useReactToPrint } from 'react-to-print';
import { useTranslation } from 'hooks/useTranslation';
import { Button, Icon } from '@gravity-ui/uikit';
import {MainPageCard} from 'components/MainPageView';
import Printer from '@gravity-ui/icons/Printer';



const QRCodePrinter = forwardRef(function QRCodePrinter({name, uuid},ref) {
    const number = name.replace(/\D/g, ''); // Removes non-numeric characters

// Pad with leading zeros to make it 4 digits
    const paddedNumber = number.padStart(4, '0');
    const serial_number = `K1A25${paddedNumber}A221B`;
    return (
        <div ref={ref} style={{visibility:'hidden', height:0, width:0}} >
            <div  id="qrcode">
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    paddingTop: '10px',
                    width:'100vw'
                }} >
                    <div
                        style ={{
                            flex:3,
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '10px',
                            alignItems: 'center',
                        }}
                    >
                        <img src={logo} height="30vh"   ></img>
                        {name}
                    </div>
                    <div
                        style={{flex:4}}
                    ><QRCode  value={'https://q-ctx.com/devices/' + uuid} size={110} />
                    </div>
                </div>
                <div style={{
                    fontSize:'18px',
                    display: 'flex',
                    paddingTop: '2px',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                    fontFamily: 'monospace',

                }}>S/N:  {serial_number}</div>
            </div>
           
            <style>
            {`@media print {
                html, body {
                    height: 100vh; /* Use 100% here to support printing more than a single page*/
                    widht: 100vw;
                    margin: 0 !important;
                    padding: 0 !important;
                    overflow: hidden;
                }
                @page {
                    size: 60mm 40mm;
                    margin: 0;
                }
                #qrcode {
                    width:100vw;
                    height:100vh;
                    
                    margin: 0;
                    padding: 0;
                    visibility: visible;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                     
                }`}
            </style>
        </div>
    );
});



const DeviceQRPrinting = ({name, uuid}) => {
    const contentRef = useRef(null);
    const reactToPrintFn = useReactToPrint({ contentRef });
    const {t} = useTranslation("device_registration");

    return (
        <MainPageCard>
            <div style = {{
                display: 'flex',
                flexDirection: 'row',
                gap: '20px',
                width: '100%',
            }}>
                <div style = {{
                    overflow: 'hidden',
                    flex:4,
                    display: 'flex',
                    flexDirection: 'column',
                    backgroundColor: '#f5f5f5',
                    padding: '20px',
                    gap: '20px',    
                }}>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            gap: '10px',
                            alignItems: 'start',
                            justifyContent: 'start',
                        }}
                        
                    >
                        <QRCode   value={"https://q-ctx.com/devices/" + uuid} size={150} />
                    <h3 style={{margin: 0,}}>{name}</h3>
                    </div>
                    {uuid}
                    <QRCodePrinter ref={contentRef} name={name} uuid={uuid}/>
                </div>
                <div
                    style= {{
                        flex:10,
                        padding: '20px',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'start',
                    }}
                >
                    {t("device_print_label_description")}
                    <Button
                        view="outlined-action"
                        style={{marginTop: '20px', width:'auto'}}
                        onClick={() => reactToPrintFn()}
                    >
                        <Icon data={Printer} size={16} style={{marginRight: '10px'}} />
                        {t("device_print_label")}
                    </Button>  
                </div>
            </div>
           
        </MainPageCard>
    )
}

export default DeviceQRPrinting;
export {QRCodePrinter};