import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import '@gravity-ui/uikit/styles/fonts.css';
import './theme/theme.css';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import {ThemeProvider} from '@gravity-ui/uikit';
import { AuthProvider, } from "react-oidc-context";
import {ToasterComponent, ToasterProvider} from '@gravity-ui/uikit';

import { oidc_config } from './constants/auth';

const onSigninCallback = () => {
  window.history.replaceState({}, document.title, window.location.pathname);
};


const ru = require('./i18n/ru.json');
const en = require('./i18n/en.json');
const kz = require('./i18n/kz.json');
const am = require('./i18n/am.json');

console.log(en)

i18n.use(initReactI18next).init({
  resources: {
    en: en,
    ru: ru,
    kz: kz,
    am:am
  },
  lng: 'ru',
  supportedLngs: ['en', 'ru','kz',"am"],
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false,
  },
});

const root = ReactDOM.createRoot(document.getElementById('root'));


root.render(
    <AuthProvider {...oidc_config} onSigninCallback={onSigninCallback}>
      <ThemeProvider theme="light">
        <ToasterProvider>
          <App/>
          <ToasterComponent/>
        </ToasterProvider>
      </ThemeProvider>
    </AuthProvider>
);
