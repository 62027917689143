import React, { useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import { Button } from '@gravity-ui/uikit';
import {QRCodePrinter} from './QRPrinter';
import qCoreApi from 'api';
import DownPageOverlay, {DownPageRightContent, DownPageTwoSidesContent} from 'components/DownPageOverlay';

const PrintLabelButton = ({ row, text}) => {
    const contentRef = useRef(null);
    const reactToPrintFn = useReactToPrint({ contentRef });
    return <><Button onClick={reactToPrintFn} view="outlined-action" >{text}</Button>
            <QRCodePrinter ref = {contentRef} name={row?.name} uuid={row?.device_id} />
    </>
}

const ActionButton = ({ row,text, onClick}) => {
    return <Button onClick={() => onClick(row)}> {text} </Button>
}

const ReportButton = ({ row,text, onClick}) => {
    return (
        <Button
            onClick={() => {
                console.log(row);
                const uuid = row?.device_id;
                const test_session_id = row?.test_session_id;

                qCoreApi.get_report(uuid, test_session_id, (response) => {
                    console.log(response);
                    const url = window.URL.createObjectURL(response);
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', `${row.name}_test_report_${uuid}.pdf`);
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                },
                (error) => {
                    console.log(error);
                });
            }}
        >
            {text}
        </Button>
    );
}

const DownPageSteps = ({hasPrevious, onPrevious, onNext, leftContent, rightContent}) => {
    return (
        <DownPageOverlay>
            {hasPrevious ?
                <DownPageTwoSidesContent>
                      <Button
                    view="action"
                    style={{ minWidth: '170px', justifySelf: 'flex-begin' }}
                    size='l'
                    width="auto"
                    onClick={() => onPrevious()}
                >
                    {leftContent}
                </Button> 
                <Button
                    view="action"
                    style={{ minWidth: '170px', justifySelf: 'flex-end' }}
                    size='l'
                    width="auto"
                    onClick={() => onNext()}
                >
                    {rightContent}
                </Button>
                </DownPageTwoSidesContent>
              : <DownPageRightContent>
                <Button
                    view="action"
                    style={{ 
                        minWidth: '170px', 
                        justifySelf: 'flex-end', 
                        gap: '10px' }}
                    size='l'
                    width="auto"
                    onClick={() => onNext()}
                >
                    {rightContent}
                </Button>
            </DownPageRightContent>
            }
        </DownPageOverlay>
    )
}


export {PrintLabelButton, ActionButton, ReportButton, DownPageSteps}